@import url(https://fonts.googleapis.com/css?family=Hind:300,400,600,700&subset=latin,latin-ext);

@import url(ticker.css);
@import url(sculpture.css);
@import url(topmenu.css);
@import url(tablestyles.css);

html.loading body::before {
    content: "";
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 100000;
}

html.loading body::after {
    content: "";
    color: #131b4d;
    font-size: 15px;
    margin: 100px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: fixed;
    text-indent: -9999em;
    animation: load4 1.3s infinite linear;
    transform: translateZ(0);
    z-index: 100000;
    left: 50%;
    top: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
}

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}




[tabindex] {
    outline: none;
}

body {
	font-family: 'Hind', sans-serif !important;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.3;
	background-color: #fff;
	margin: 0;
	padding: 0;
	color:#131b4d;
	text-rendering: optimizeLegibility;
}

@media all and (max-width: 1010px) {
    body {
	    font-size: 16px;
    }
}

@media all and (max-width: 766px) {
    body {
	    font-size: 18px;
    }
}

a {
	color:#232157; 
	text-decoration: none;
	cursor: pointer;
}

    a:hover {
        text-decoration: underline;
    }

    a.no-link:hover {
        text-decoration:none;
    }

.container {
    width:100%;
    box-sizing: border-box;
}

img {
    max-width:100%;
    border: 0;
}

b {
    font-weight: 700;
}
h1, h2, h3, h4, h5, h6 {
    line-height: inherit;
}
input[type='text']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
input[type='number']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder {
    color:#232157;
}

button, input, textarea {
	-webkit-appearance: none;
	-moz-appearance:    none;
	appearance:         none;
	font-family: 'Hind', sans-serif;
	font-weight: 400;
	border-radius:0;
}

.nowrap {
    white-space: nowrap;
}

/* preview yellow bar input */
input.publishedInput {
	-webkit-appearance: checkbox;
	-moz-appearance:    checkbox;
	appearance:         checkbox;
}
/**/
input:placeholder-shown,
textarea:placeholder-shown {
    color:#232157;
}

button,
input[type='submit'],
input[type='button'] {
    cursor:pointer;
}

input[type='text'],
input[type='date'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='text'],
textarea {
    border:1px solid #b4b9bd;
    padding:0 5px;
    height:auto;
}

input[type='text']:focus,
input[type='date']:focus,
input[type='password']:focus,
input[type='text']:focus,
textarea:focus {
    outline:2px solid rgba(50, 46, 164, .5);
}
.label {
    padding: 0;
}
.btn.btn {
    background-color:#cc9933;
    color:#fff;
    padding:0 30px;
    font-size:18px;
    border:0 none;
    height:38px;
    text-transform:uppercase;
    position: relative;
    overflow: hidden;
}

.btn.btn:hover {
	background-color:#ba8c5d;
}

.btn.btn.disabled {
    line-height: 38px;
    background-color: #dfdfd0;
    cursor: default;
}
.ladda-button[data-style=expand-right] .ladda-spinner {
    right: -12px;
}

.inputForms input {
    padding: 4px 16px;
    height: auto;
}

.inputForms input.hasDatepicker {
    padding-right: 35px;
}

/* buttons */
.primary-btn.primary-btn {
    color: #232157;
    background-color: #e2e5e8;
}

.primary-btn.primary-btn:hover {
    background-color: #232157;
    color:#f3f3f6;
}

/* arany színű gomb */
.secondary-btn.secondary-btn {
    background-color: #cc9933;
    color:#fff;
}

.secondary-btn.secondary-btn:hover {
    background-color: #232157;
    color:#f3f3f6;
}

table th {
    font-weight:400;
}

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  background-clip: content-box;
  box-sizing: border-box;
}

article, aside, footer, header, hgroup, main, nav, section {
    display: block;
}

h1, h2, h3, h4, h5, h6 {
	font-weight:normal;
}

h1 {
	font-size: 32px;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
}

h2 {
	font-size: 28px;
	margin-top: 0.83em;
	margin-bottom: 0.83em;
	line-height: 1.3;
}

h3 {
    font-size:20px;
}
.article-title {
    margin-top: 1em;
    margin-bottom: 1em;
	line-height: 1.3;
}
@media only screen and (max-width: 880px) {
    h1 {
    	font-size: 28px;
    }
    
    h2 {
    	font-size: 24px;
    }
    
    h3 {
        font-size:16px;
    }
}

@media only screen and (max-width: 766px) {
    h1 {
    	font-size: 32px; /*28px*/
    }
    
    h2 {
    	font-size: 24px;
    }
    
    h3 {
        font-size:20px;
    }
}

h1.sectionhead {
    margin-left: 16px;
    color:#232157;
}

h2.sectionhead {
    margin-left: 16px;
    color:#232157;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
}

@media all and (max-width: 766px) {
    h1.sectionhead,
    h2.sectionhead {
        margin-left: 0px;        /* margin:8px 0px 16px;*/
    }
}

a.linkwitharrow {
    background: transparent url(../../../../site-default/modules_site/site/images/kacsacsor.svg) no-repeat 0 40%;
    padding-left: 15px;
}

strong {
    font-weight: 600;
}


.mbtm {
   margin-bottom:16px;
}

@media all and (max-width: 766px) {
    .mbtm {
        margin-bottom:16px;
    }
}

@media all and (max-width: 480px) {
    .mbtm {
        margin-bottom:8px;
    }
}

@media all and (max-width: 640px) {
    .nodisplay-mobile {
        display:none;
    }
}

/*____ HEADER */

header .header-top {
    padding: 12px 16px;
    display: flex;
    column-gap: 20px;
}

header .header-top > .column:first-child{
    flex-grow: 1;
    flex-shrink: 0;
}

.BetSolrSearchPortlet {
    clear:both;
    max-width: 600px;
    margin-left: auto;
}

@media (max-width: 500px) {
    header .header-top {
        padding:16px 8px;
        position:relative;
    }
}

/*header .header-top:after {
    content:'';
    clear:both;
    overflow:hidden;
    display:block;
}*/

.ThemeSwitcherPortlet li.active {
    display:none;
}

@media all and (max-width: 950px) {
    .mbtm {
        margin-bottom:10px;
    }
}

.header-top .inner-flex {
    /*float:right;
    outline:0 none;
    width: 48%; 41.3%; 34% volt*/
    text-align: right;
}

@media (min-width: 766px) {
    .header-top .inner-flex {
        /*min-width:425px;*/
        position:relative;
    }
}

@media (min-width:500px) and (max-width: 766px) {
    .header-top .inner-flex {
        margin-right: -10px;
    }
}

/*@media all and (max-width: 1295px) {
    .header-top .inner-flex {
        width:60%;
    }
}
@media all and (max-width: 1070px) {
    .header-top .inner-flex {
        width:70%;
    }
}
@media all and (max-width: 900px) {
    .header-top .inner-flex {
        width:83%;
    }
}
@media all and (max-width:600px) {
    .header-top .inner-flex {
        width:77%;
    }
}
@media all and (max-width:400px) {
    .header-top .inner-flex {
        width:70%;
    }
}*/
.ThemeSwitcherPortlet,
.LanguageSelectPortlet {
    display: inline-block;
    /* height: 35px; */
    /* float: left; */
    vertical-align: middle;
    text-align: initial;
}

@media (min-width: 766px) {
    .header-nav-social {
        /* position:absolute; */
        right:0;
    }
}

@media (max-width: 766px) {
    .LanguageSelectPortlet {
        /* height: 35px; */
        /* margin-top: 5px; */
        /* float: right; */
        vertical-align: middle;
    }
    
    .ThemeSwitcherPortlet {
        /* height: 35px; */
        float: right;
        vertical-align: middle;
        margin-left: 0px;
        overflow: hidden;
        margin-top: -5px;
    }
    
    .BetSolrSearchPortlet {
        width: 100%;
        clear: both;
    }
}

@media (max-width: 500px) {
    .LanguageSelectPortlet {
        width: auto;
        height: auto;
        margin-top: 0;
        float:right;
    }
    .LanguageSelectPortlet li {
        margin: 0;
    }
    
    .ThemeSwitcherPortlet {
        clear: both;
        right: 0;
        height: auto;
    }

    .inner-flex {
        vertical-align: middle;
    }
    
    .header-nav {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
    }
}

.header-top .top-row > div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /* margin: 0 16px 0 0; */
}

.header-top .top-row:first-child  > div{
    /* margin-top: 3px; */
}

@media (max-width: 766px ) {
    .header-top .top-row > div {
        position:relative;
        width: auto;
        /* margin: 0 0 16px 0; */
    }
}

@media (max-width: 500px ) {
    .header-top .top-row > div {
        margin-right:0;
        margin-bottom: 0; /*25px*/
    }
}

.header-top .bottom-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:end;
    -webkit-justify-content:flex-end;
        -ms-flex-pack:end;
            justify-content:flex-end;
}

.header-top a.logo {
    background: url(../../../../site-default/modules_site/site/images/BET-egyszerusitett.svg) 0 0 no-repeat; /*BET30_logo.png*/
    background-size: 100%;
    background-size: 100px 80px;  /*ez a sor nem kell*/
    width:100px; /*150px*/
    height:80px;
    display:block;
    float:left;
    outline:0 none;

}

@media (max-width: 905px) {
    .header-top a.logo {
        margin-top: 15px;
        /*width: 100px;
        height: 60px;*/
    }
}

.header-top a.logo h1 {
    display:none;
}

@media (max-width: 500px) {
    .header-top a.logo {
        width:80px;
        height:64px;
        background-size: 80px 64px;
    }
}

.header-top .logo:hover {
    /*-webkit-transform: scale(1.005);
        -ms-transform: scale(1.05);
            transform: scale(1.05);*/
}

.header-top ul {
    margin:0;
    padding:0;
}

.header-top ul li {
    font-size: 16px;
    display:inline-block;
}

@media (max-width: 766px) {
    .header-top ul li {
        font-size: 14px;
    }
}

.header-top ul li a {
    margin: 0;
    color: rgba(35,33,87,0.85);
    padding: 8px;
    display: inline-block;
}

@media (max-width: 766px) {
    .header-top .header-nav {
        margin: 0;
    }
}

@media (max-width: 1010px) {
    .header-top .bottom-row {
        margin-bottom:10px;
    }
}

@media (max-width: 500px) {
    .header-top ul li a {
        padding:8px;
        display:block;
    }
    
    .header-top ul.header-nav-social li a {
        padding: 6px 8px; /* 0 */
    }
    
    .header-top .bottom-row {
        margin:0;
    }
}

.header-top .header-nav li {
    margin: 0 0 0 16px;
}
.sign-in.loggedout {
    margin-left: 0 !important;
}

@media (max-width: 560px) {
    .header-top .header-nav li {
        margin: 0;
    }
}

@media (max-width: 766px) {
    .header-top .contrast {
        display: none;
    }
}

.header-top .contrast a {
    background: url(../../../../site-default/modules_site/site/images/contrast.svg) no-repeat 0 0;
    /* padding:0 0 0 28px; */
    background-size: 24px 25px;
    width: 25px;
    height: 25px;
    background-position: 50%;
}

.header-top .header-nav li:first-child,
.header-top .header-nav2 li:first-child,
.header-top .header-nav-social li:first-child {
	margin-left:0;
}

.header-nav-social li {
    margin: 0;
    padding: 0;
}
.header-nav-social a {
    padding: 0;
}

header .fb a,
header .youtube a,
header .linkedin a {
    background-repeat: no-repeat;
    background-size: 23px 24px;
    display: inline-block;
    vertical-align:middle;
    font-size: 0;
    line-height: 0;
    width: 24px;
    background-position: 50%;
    height: 27px;
}

header .fb a {
  background-image: url(../../../../site-default/modules_site/site/images/fb.svg); }

header .youtube a {
  background-image: url(../../../../site-default/modules_site/site/images/youtube.svg); }

header .linkedin a {
  background-image: url(../../../../site-default/modules_site/site/images/in.svg);
}

ul.subord-menu {
    line-height:36px;
}

header .aboutus::before {
    display: inline-block;
    vertical-align: baseline;
    width: 1px;
    height: 0.75em;
    background-color: #302e61;
    content: "";
    color: #302e61;
    margin-left: 15px;
    margin-right: 19px;
}

@media (max-width: 500px){
    header .aboutus::before {
        margin-left: 5px;
        margin-right: 8px;
    }
}

header .search {
    /* height: 34px; */
}

@media (max-width:500px) {
    
    header .search-wrapper {
        display:none;
    }
    
    header .search-wrapper.opened {
        position:absolute;
        display:block;
        bottom: -120px;
        padding: 20px;
        box-sizing: border-box;
        background-color: #e2e5e8;
        width: 100%;
        right: 0;
        z-index: 110;
        box-shadow: 4px 7px 10px 0px rgba(0, 0, 0, 0.2);
    }
}

.search-opener {
    display:none;
}

@media (max-width: 500px) {
    .search-opener {
        display:block;
        position:absolute;
        bottom:-46px;
        right:0;
        height:46px;
        width:46px;
        background:url(../../../../site-default/modules_site/site/images/magnifier-white.svg) no-repeat 50%;
        z-index:110;
        background-size:24px 24px;
        cursor: pointer;
    }
    
    .search-opener.active {
        background:#e2e5e8 url(../../../../site-default/modules_site/site/images/menu-close.svg) no-repeat 50% 60%;
    }
}

header .search span {
    border: 1px solid #b4b4b4;
    background-color:#fff;
    border-right: 0;
    width: 100%;
    z-index:105;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

body.isInFullscreen header .search span {
    z-index:0;
}

  header .search input[type='text'] {
    border: 0;
    padding: 4px 0 0 15px;
    font-size: 16px;
    height: 34px;
    box-sizing: border-box;
    color: #232157;
    width:100%;
  }

  header .search input:placeholder-shown {
	  text-transform:uppercase;
	  color:#232157;
	}
	
  header .search button {
    font-size: 12px;
    border: 0;
    height: 34px;
  }
    
    header .search .search-det {
        display: inline-block;
        background: url(../../../../site-default/modules_site/site/images/arrow-right.svg) no-repeat right 50%;
        font-size: 14px;
        padding: 0 12px 0 0;
        margin: 0 20px 0 0;
        color: #8a8a8a;
    }

    header .search .search-det::before {
        display: inline-block;
        margin: 0 15px 0px 0px;
        background-color: #8a8a8a;
        content: '';
        width: 1px;
        height: 16px;
        vertical-align: sub;
    }
    
@media (max-width: 766px) {
    header .search .search-det {
        font-size: 12px;
    }
}
    
    header .search .search-norm,
    .reg-box div .search-norm {
        margin: 0;
        font-size: 16px;
        line-height:38px;
        height:36px;
        padding:0 13px;
        border:1px solid #7b7a9a;
        border-radius:0;
    }

header section {
    height: 36px;
    border-bottom: 1px solid #282828;
}

/*--- TIMETABLE ---*/
.timetable {
    margin-bottom:20px;
}

.timetable h3 {
    margin: 0;
    padding: 9px 16px 3px;
    border-bottom: 1px solid #7b7a9a;
    color: #232157;
    text-transform: uppercase;
}

.timetable .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 15px 16px 0;
    color: #232157;
}

@media (max-width: 1010px) {
    .timetable .row {
        margin: 15px 10px 0;
    }

    .timetable h3 {
        font-size:18px;
    }
}

@media (max-width: 766px) {
    .timetable .row {
        margin: 15px 16px 0;
    }

    .timetable h3 {
        font-size:21px;
    }
}
.timetable .row a:first-child {
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.timetable .row span:last-child {
    display: block;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

/* BREADCRUMB ------------------ */
.breadcrumb {
    list-style-type: none;
    margin: 0;
    padding: 8px 16px;
    width: auto;
    border-top: 1px solid #7b7a9a;
	border-bottom: 1px solid #7b7a9a;
	font-size: inherit;
	cursor: default;
}

@media (max-width: 1010px) {
    .breadcrumb-fw {
        margin:0 16px;
    }
}

@media (max-width: 480px) {
    .breadcrumb-fw {
        margin:0 8px;
    }
    
    .breadcrumb {
        padding: 8px;
    }
    
}

.breadcrumb li {
    font-size: 14px;
    text-transform: uppercase;
	line-height: 1em;
	font-weight: 400;
	display:inline-block;
}

.breadcrumb li::after {
    content:"/";
}
.breadcrumb li:last-child::after{
    content:none;
}
.breadcrumb li ~ li:before {
    display: none;
}

header .breadcrumb {
    margin-top: 16px;
}

header .lister-top .DynamicBreadcrumbPortlet .breadcrumb {
    margin: 16px auto 0 auto;
    box-sizing: border-box;
}

.breadcrumb li a {
    cursor: default;
    text-decoration: none;
}
.breadcrumb li a[href] {
    cursor: pointer;
}
.breadcrumb li a[href]:hover {
    text-decoration: underline;
}

/* ERROR ----------------------*/
.error {
    margin: 32px 5% 0 5%;
}

/* MAIN BUX -------------------*/
.BuxIndexPanelPortlet {}

.ax_dynamic_panel {
    background-color:#f3f3f6;
    padding:18px;
    width:100%;
    max-height:560px;
    box-sizing:border-box;
}

@media (max-width: 766px) {
    .ax_dynamic_panel {
        padding:12px;
    }
}

@media (max-width: 766px) {
    .ax_dynamic_panel { max-height:none; }
	.ax_dynamic_panel .row { margin-bottom:5px; }
}

.ax_dynamic_panel .row {
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
        -ms-flex-pack:justify;
            justify-content:space-between;
}
.ax_dynamic_panel .row > span {
    display: block;
}

@media (max-width:766px) {
    .ax_dynamic_panel .row span.label { 
        margin-right:20px;
    }
    
    .ax_dynamic_panel .line .row span.label {
         margin-right:0;
    }
}

.ax_dynamic_panel .line .line-div {
    height: 4px;
    width:100%;
    border:1px solid #e2e5e8;
    background:url(../../../../site-default/modules_site/site/images/minmax-line.png) no-repeat 0 0;
    background-size:100%;
    margin:0 0 10px;
}

.ax_dynamic_panel .line {
    margin: 16px 0;
    position:relative;
    padding-top: 27px;
    font-size:16px;
}

.ax_dynamic_panel .line {
    margin: 15px 0;
    position:relative;
    padding-top: 27px;
    font-size:16px;
}


@media (max-width:1010px) {
    .ax_dynamic_panel .line {
        font-size:14px;
    }
}

@media all and (max-width: 766px) {
    .ax_dynamic_panel .line {
        font-size:16px;
    }
}

.ax_dynamic_panel .line .actual {
    position:absolute;
    left:10%;
    top: 0;
    text-transform:uppercase;
}

.ax_dynamic_panel .line .actualDot {
    position:absolute;
    left:10%;
    top: 0;
}

.ax_dynamic_panel .line .actualDot {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: #060524;
    position: absolute;
    border: 2px solid #f3f3f6;
    border-radius: 8px;
    left: 50%;
    margin-left: -10px;
    bottom: -15px;
    top:29%;
}

.ax_dynamic_panel .line .row div {
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -webkit-flex-direction:column;
        -ms-flex-direction:column;
            flex-direction:column;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
}

.ax_dynamic_panel .line .row div.right {
    text-align:right;
}

.ax_dynamic_panel h2.title {
    margin:0;
    font-size: 21px;
    text-transform:uppercase;
    color: #232157;
}

.ax_dynamic_panel .row.closing-time {
    color:#8a8a8a;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
        -ms-flex-pack:justify;
            justify-content:space-between;
    margin-bottom:10px;
    /* font-style: italic; */
     font-weight: 300; 
    /* letter-spacing: 0.02em; */
}

.ax_dynamic_panel .row.closing-time .label {
    margin-right:5px;
}

.ax_dynamic_panel .up {
    color:#50924d;
}
.ax_dynamic_panel .down {
    color:#cc4533;
}

#buxIndexPanel-index {
    font-size:48px;
    font-weight:400;
    line-height: 45px;
    overflow:hidden;
}

@media(max-width:766px) {
    #buxIndexPanel-index {
        line-height:1.3;
    }
}

#buxIndexPanel-index:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    margin-right: 15px;
    margin-top: -8px;
    vertical-align:middle;
}
    #buxIndexPanel-index.up:before {
        border-bottom: 12px solid #50924d;
    }

    #buxIndexPanel-index.down:before {
        border-top: 12px solid #cc4533;
    }

.ax_dynamic_panel .value {
    letter-spacing: 0.01em;
    white-space:nowrap;
}

#buxIndexPanel .graphicon .chart-heading { display: none; }

/*__________________________*/
.left-links {
    margin: 0 0 2px;
}

.left-links > a {
    background: #e2e5e8 url(../../../../site-default/modules_site/site/images/kacsacsor.svg) no-repeat 95% 50%;
    display: block;
    font-size: 20px;
    padding: 12px 40px 7px 16px;
    text-transform: uppercase;
    color: #232157;
    cursor:pointer;
}

.left-links > a:hover,
.left-links.hover > a {
    text-decoration:none;
    background-color:#f3f3f6;
}

@media(max-width:1010px) {
    .left-links > a {
        font-size: 18px;
        padding: 12px 30px 7px 16px;
    }
}

@media(max-width:500px) {
    .left-links > a {
        font-size:21px;
    }
}

@media(max-width:420px) {
    .left-links > a {
        font-size:18px;
    }
}

/*__________________________*/

.block2 .banners > .StaticContentPortlet {
    display: block;
}

@media (max-width: 766px) {
    main .block2 .banners {
        margin-bottom: 20px;
    }    
}

.banner {
    margin-bottom:16px;
    max-width:180px;
}

@media (max-width: 1010px) {
    main .block2 .banners {
        float:none;
    }
    
    main .block2 .banners .StaticContentPortlet {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        margin: 20px -10px 0;
    }
    
    main .banner {
        margin: 10px;
    }
}

@media (max-width: 880px) {
    main .banner {
        margin-left:5px;
        margin-right:5px;
    }
}


.banner a {
	position: relative;
    color: #fff;
    z-index: 0;
    text-transform: uppercase;
    font-size: 0px;
    display:block;
}

.banner a div {
	position: absolute;
    top: 0;
    right: 0;
    left: 0;
    font-size: 20px;
    z-index: 1;
    padding: 8px 16px;
    text-align: right;
    background: rgba(35, 33, 87, 0.7);
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 766px) and (max-width: 880px) {
    .banner a div {
        font-size: 18px;
        padding: 8px 10px;
    }
}

@media (max-width: 560px) {
    .banner a div {
        font-size: 18px;
        padding: 8px 10px;
    }
}

/*__________________________*/

.slide {
  width: 100%;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.bet-slide a {
    display:block;
}

.bet-slide {
  position: relative;
  width: 100%;
}

.bet-slide img {
    max-width:100%;
    width:100%;
}

.text-div {
    padding: 24px 20% 20px 16px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    position: absolute;
    bottom:0;
}

@media (max-width: 766px) {
    .text-div {
        padding: 16px 16px 36px;  /* padding: 12px 16px 38px; */
    }
}

@media (max-width: 500px) {
    .text-div {
        /* padding: 12px 16px 38px; */
        background-color: rgba(255, 255, 255, 0.6);
    }
}

.content-list-elements {
    border: 0;
}
.image-title {
    font-size: 28px;
    color: #060524;
    bottom: 0;
    margin: 0;
}

    @media (max-width: 1010px) {
        .image-title {
            font-size: 24px;
            padding: 0;
        }
    }
  
@media (max-width: 766px) {
    .image-title {
        font-size: 24px;
        padding:0;
    }
}

@media (max-width: 640px) {
    .image-title {
        font-size: 20px;
        padding:0;
    }
}

/* Kibocsatoi hirek -------*/

.NewkibListPortlet .row {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
        -ms-flex-pack:justify;
            justify-content:space-between;
}

@media (max-width:480px) {
    .NewkibListPortlet .row {
        display:block;
    }
}

.NewkibListPortlet .row h2,
.NewkibListPortlet .row .right-element {
    margin: 0;
    position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
  font-weight: 400;
}

.articles {
  box-sizing: border-box;
  width: calc(100% - 196px); }
  
    @media (max-width: 1010px) {
        .articles { width: 100%; }
    }

/* common font sizes */
.articles li h2 {
	font-size:20px;
}

.articles li span.list-date {
	font-size:16px;
	white-space:nowrap;
}

.articles li .title {
	font-size:18px;
}

@media(max-width: 1010px) { 

	.articles li h2 {
		font-size:18px;
	}

	.articles li span.list-date {
		font-size:14px;
	}

	.articles li .title {
		font-size:16px;
	}
}
 
@media(max-width: 766px) { 
	.articles li h2 {
		font-size:20px;
	}
	
	.articles li span.list-date {
		font-size:16px;
	}
	 
	.articles li .title {
		font-size:18px;
	}
}
   
  .articles .article-group-header {
    background-color: #232157;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 12px 0 8px;
    line-height: 26px;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    }

    .articles .article-group-header h2 {
        display: block;
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        box-sizing: border-box;
        margin: 0;
      -webkit-box-flex: 1;
      -webkit-flex: 1 1 auto;
          -ms-flex: 1 1 auto;
              flex: 1 1 auto;
        line-height: inherit;
        padding: 0;
        margin: 0 16px;
    }
    
    .articles .article-group-header h2 a { 
        color:#fff;
        font-size: inherit;
    }
    
    @media (max-width: 1010px) {
        .articles .article-group-header h2 {
            font-size: 18px;
            margin: 0 10px;
        }
    }
          
    @media (max-width: 766px) {
        .articles .article-group-header h2 {
            font-size: 21px;
            margin: 0 16px;
        }
          .articles > .article-group-header h2:hover {
            -webkit-transform: scaleX(1.02) scaleY(1.03);
                -ms-transform: scaleX(1.02) scaleY(1.03);
                    transform: scaleX(1.02) scaleY(1.03); }
          
    }
      
    @media (max-width: 480px) {
        .articles .article-group-header h2 {
            font-size: 19px;
            margin: 0 0 0 16px;
        }
    }

    .articles .article-group-header .newsletter,
    .articles .article-group-header .newssearch {
      display: block;
      font-size: 16px;
      /*color: #7b7a9a;*/
      color: #fff;
      padding-right: 15px;
      text-decoration: none;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto; }
              
      @media (max-width: 1010px) {
        .articles .article-group-header .newsletter, .articles .article-group-header .newssearch {
          padding-right: 10px; }
      }
          
      @media (max-width: 766px) {
        .articles .article-group-header .newsletter, .articles .article-group-header .newssearch {
          font-size: 0;
          padding: 5px 15px; }
      }

    .articles .article-group-header .newsletter:hover,
    .articles .article-group-header .newssearch:hover {
        text-decoration:underline;
    }
        
  .articles .newsletter::before {
        display: inline-block;
        content: "";
        width: 18px;
        height: 18px;
        background-image: url(../../../../site-default/modules_site/site/images/pen-white.svg);
        background-repeat: no-repeat;
        vertical-align: middle;
        padding-right: 5px;
    }
    
    @media (max-width: 1010px) {
      .articles .newsletter::before {
        padding-right: 5px; }
    }

    @media (max-width: 766px) {
      .articles .newsletter::before {
        width: 24px;
        height: 24px;
        background-size: auto 100%;
          padding-right: 0; 
      }
    }
        
  .articles .newssearch::before {
    display: inline-block;
    content: "";
    width: 18px;
    height: 18px;
    background-image: url(../../../../site-default/modules_site/site/images/magnifier-white.svg);
    background-repeat: no-repeat;
    vertical-align: middle;
    padding-right: 10px;
  }
    
    @media (max-width: 1010px) {
        .articles .newssearch::before {
            padding-right: 5px;
        }
    }
    
    @media (max-width: 766px) {
    .articles .newssearch::before {
            width: 24px;
            height: 24px;
            background-size: auto 100%;
            padding-right: 0;
        }
    }
    
    .articles ul {
        margin: 0;
        padding: 0;
        list-style:none;
    }

    .articles ul li a {
        color: #060524;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack:justify;
        -webkit-justify-content:space-between;
            -ms-flex-pack:justify;
                justify-content:space-between;
        padding: 16px 16px 12px 16px;
        -webkit-transition: background-color 0.2s ease;
        transition: background-color 0.2s ease;
    }
    
    @media (min-width: 766px) and (max-width: 1010px) {
        .articles ul li a {
        padding: 16px 10px;
        }
    }
    
    .articles ul li a:hover {
        background-color:rgba(123,122,154,0.1);
        text-decoration:none;
    }
    
    .articles ul li {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #e2e5e8;
    }

    a.more {
        display: block;
        color: #8a8a8a;
        font-size: 16px;
        text-align: right;
        padding: 0 16px;
        margin: 16px 0 16px;
    }
    
    a.more:after {
        display: inline-block;
        content: "";
        height: 12px;
        width: 6px;
        background-image: url(../../../../site-default/modules_site/site/images/kacsacsor-gray.svg);
        background-repeat: no-repeat;
        margin-left: 8px;
        margin-bottom: -1px;
    }
    
    @media (max-width: 1010px) {
        a.more {
            font-size:14px;
        }
        
        a.more:after {
            height: 10px;
            width: 4px;
            background-size:contain;
            margin-left: 5px;
        }
    }
    
    @media (max-width: 766px) {
        a.more {
            font-size:16px;
        }
    }
    
    span.list-date {
        font-size:16px;
        margin-top:5px;
        color:#8a8a8a;
        white-space:nowrap;
    }

    .issuernews-list ul li a {
        display:block;
    }

    .issuernews-list .row {
        display:-webkit-box;
        display:-webkit-flex;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-pack:justify;
        -webkit-justify-content:space-between;
            -ms-flex-pack:justify;
                justify-content:space-between;
    }
    
    .issuernews-list h2 {
        margin: 0;
        width: 75%;
        color: #060524;
    }
    
    .issuernews-list .title {
        color:rgba(6,5,36,0.70);
    }

    @media (max-width: 1010px) {
        span.list-date.today {
            color:#232157;
        }
    }
    @media (max-width: 766px) {
        span.list-date {
            /*font-size:14px;*/
        }
    }
    
    @media (max-width: 480px) {
        span.list-date {
            /*margin-bottom:8px;*/
        }
    }
    
    .articles .title-element {
        margin:0;
        width:75%;
    }
    
    @media (max-width: 480px) {
        .articles .title-element {
            width:auto;
        }
    }
    .articles .right-element {
        display: inline-block;
        content: "";
        box-sizing: border-box;
        padding-right: 40px;
        width: 100%;
        position: absolute;
        top: 8px;
        font-size: 16px;
        color: #8a8a8a;
        text-align: right;
    }
    @media (max-width: 1010px) {
        .articles .right-element {
            top: 11px;
            font-size: 14px;
            padding-right: 20px;
        }
    }
    @media (max-width: 766px) {
        .articles .right-element {
            padding-right: 30px;
            }
        }
    @media (max-width: 1010px) {
        .articles .description-element {
            font-size: 14px;
        }
    }
    .articles ul .new-article ul li:nth-child(2) {
        color: #232157;
    }


footer {
    background: #232157 url(../../../../site-default/modules_site/site/images/bet-logo.svg) no-repeat 40px 30px;   /*BET30_logo_footer.png*/
    font-size: 15px;
    color: #abaaca;
    margin: 0;
    position: relative;
    padding: 30px 20px;
    /*background-size: 128px 128px;*/
}


footer a {
    color: #abaaca;
    font-size: 18px;
    display:block;
    line-height: 1.3;
}
footer nav {
    padding: 25px 5% 0 18%;
}
.StaticContentPortlet nav {
    padding-top: 0;
}
footer .footer-select {
    display: none;
    position: absolute;
    right: 0;
    margin-right: calc(5% + 18px);
    margin-top: -23px;
    margin-bottom: 0;
}
footer .footer-select .portlet-heading {
    display: none;
}

@media (max-width: 1280px) {
    footer {
        background-position-x: 20px;
    }
}


@media (max-width: 1010px) {
    footer nav {
        padding-left: 15%;
        padding-right: 3%;
    }
    footer .footer-select {
        margin-top: -32px;
        margin-right: calc(3% + 18px);
    }
    footer a {
        padding:5px 0;
    }
    footer {
        background-size: 100px 100px;
        /*background-size: 100px auto;*/
        background-position: 20px 30px;
    }
}

@media (max-width: 860px) {
    footer nav {
        padding-right: 0;
        padding-top: 0;
    }
    footer a {
        font-size: 16px;
        padding:6px 16px;
    }
    footer .footer-select {
        margin-right: 18px;
        margin-top: -30px;
        width: inherit;
    }
}

@media (max-width: 800px) {
    footer nav {
        padding-left: 20%;
        padding-right: 10px;
    }
    
    footer .footer-select {
        margin: -30px 30px 0 auto;
    }
}

@media (max-width: 718px) {
    footer nav {
        padding-left: 25%;
        padding-right: 0;
    }
    
    footer .footer-select {
        margin: -30px 75px 0 auto;
    }
}

@media (max-width: 600px) {
    footer nav {
        padding-left: 25%;
        padding-right:0;
    }
    
    footer .footer-select {
        margin: -30px 30px 0 auto;
    }
}

footer nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

footer nav ul li {
    margin: 0;
}

footer nav p {
    margin: 30px 0 40px;
    line-height: normal;
}

@media (max-width: 1010px) {
    footer p {
        font-size: 12px;
    }
}

@media (max-width: 860px) {
    footer nav p {
        margin: 20px 0 30px 16px;
    }
}
@media (max-width: 766px) {
    footer nav p {
        margin: 20px 0 30px 16px;
    }
    
    footer p {
        font-size: 14px;
    }
}

footer .copyright {
    display: block;
    margin: 0 auto;
    padding: 25px 0 30px;
    clear: both;
    border-top: 1px solid #403f65;
    text-align: center;
}

@media (max-width: 766px) {
    footer .copyright {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    footer {
        background-image:none;
    }
    footer nav {
        padding:0;
    }
    footer nav p {
        background: url(../../../../site-default/modules_site/site/images/bet-logo.svg) no-repeat 0 0;   /*BET30_logo_footer.png*/
        /*background-size:90px 90px;*/
        background-size: 90px auto;
        padding-left:110px;
        min-height:90px;
        margin-left:0;
    }
    footer nav ul {
        margin: 0;
        padding: 0;
        display: block;
        text-align: center;
    }
    footer nav ul li {
        margin: 0 0 8px 0;
        display: inline-block;
        text-align: center;
    }
    footer .footer-select {
        margin-top: -50px;
        margin-right: 20px;
    }
    footer a {
        padding: 5px;
    }
}
@media (max-width: 388px) {
    footer a {
        padding: 8px;
    }
    footer .footer-select {
        margin: -56px 40% 0;
    }
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.header-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.chart .slide .aside-nav .articles .banner
.sign-in .reg .english .contrast .fb .youtube .linkedin .contact .aboutus .search {
  width: 100%; }

footer nav ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start; }


@media all and (max-width: 766px) {

  .aside-nav {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .slide {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
        -ms-flex-order: 3;
            order: 3; }
  .articles {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
        -ms-flex-order: 4;
            order: 4; }
  .banner {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
        -ms-flex-order: 5;
            order: 5; }
}

.tableDate {
    color: #8A8A8A;
    font-weight: 300;
    text-align: left;
    font-size: 85%;
    margin-right: 8px;
    float: left;
}

a.downloadLink {
	text-decoration:none;
	line-height:normal;
	vertical-align:middle;
	font-weight: 400;
	border: 0;
	background:url(../../../../site-default/modules_site/site/images/datadwl-icon.svg) no-repeat 0 0;
	padding:0 0 0 30px;
	color:#232157;
	margin:0 0 0 16px;
}

a.downloadLink {
    margin:0;
}

/* JQUERY dialog container commons */
div.dialog-container {
    border:0 none;
}


.ProductDownloadPortlet .inputCell hr,
.ProductDownloadPortlet .textCell hr {
    border: 0 none;
    height: 1px;
    background: #e2e5e8;
    margin: 24px 0;
}

.ProductDownloadPortlet a {
    text-decoration:underline;
}

.derivative_instrument .largetable th select {
    margin-left:8px;
}

input {
    font-size: 18px;
    vertical-align: middle;
}
.sub-field > input {
    padding: 2px 0 0 8px;
}

@media (min-width: 766px) and (max-width: 1100px) {
    input {
        font-size: 16px;
    }
}
.hasDatepicker {
    width: 150px;
}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
    width: 49%;
    font-family: 'Hind', sans-serif !important;
}

@media (min-width: 766px) and (max-width: 1100px) {
    .hasDatepicker {
        width: 120px;
    }
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
    display: none;
}

/* SEARCH */

.BetBetSolrSearchResultPortlet h3 {
    margin: 30px 0 5px;
}

.solr-search-container .hasDatepicker {
    background: url(../../../../site-default/modules_site/site/images/calendar-ico.svg) no-repeat right 10px center #fff;
    background-size: 24px 24px;
    padding:6px 0px 4px 8px;
    color:#8a8a8a;
    margin-right:4px;
    width: 130px;
}

@media (max-width: 1010px) {
    .solr-search-container .hasDatepicker {
        margin-bottom:16px;
    }
}

@media (max-width:768px) {
    .solr-search-container .hasDatepicker {
        width: 40%;
    }
}

@media (max-width: 766px) {
    .BetSolrSearchResultPortlet .flex {
        display: block;
    }
    
    .BetSolrSearchResultPortlet .mobile-header {
        display: block;
        width: auto;
        box-sizing: border-box;
        margin: 0;
        padding: 16px 8px;
    }
    
    .BetSolrSearchResultPortlet h3 {
        padding: 10px 0;
        cursor: pointer;
        
    }
    
    .BetSolrSearchResultPortlet h3:before {
        content: '+';
        font-size: 24px;
        margin-right: 4px;
    }
    
    .BetSolrSearchResultPortlet .open h3:before {
        content:'-';
    }
    
    .BetSolrSearchResultPortlet .solr-search-results-container,
    .BetSolrSearchResultPortlet .solr-search-params {
        width: 100% !important;
        padding: 0;
    }
    
    .BetSolrSearchResultPortlet .solr-drill-drown-params {
        margin: 0;
    }
    
    .BetSolrSearchResultPortlet .mobile-header .solr-drill-drown-param {
        display: none;
    }
    
    .BetSolrSearchResultPortlet .mobile-header.open .solr-drill-drown-params .solr-drill-drown-param {
        display: block;
        position: relative;
        cursor: pointer;
        border-top:1px solid #e2e5e8;
        margin-bottom:0;
        padding: 8px 0;
    }

    .BetSolrSearchResultPortlet .mobile-header.open .solr-drill-drown-params .solr-drill-drown-param h4 {
        color: #232157;
        font-size: 20px;
        margin: 0;
        padding: 8px 0;
    }
    
    .BetSolrSearchResultPortlet .mobile-header.open .solr-drill-drown-param > *:hover {
        text-decoration: underline;
    }
    
    .BetSolrSearchResultPortlet .mobile-header.open .solr-drill-drown-param h4:after {
        content: "";
        position: absolute;
        right: 16px;
        top: 23px;
        width: 14px;
        height: 6px;
        background-image: url("../../../../site-default/modules_site/site/images/menuopen.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        -webkit-transition: 0.3s -webkit-transform;
        transition: 0.3s -webkit-transform;
        transition: 0.3s transform;
        transition: 0.3s transform, 0.3s -webkit-transform;
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    .BetSolrSearchResultPortlet .mobile-header .solr-drill-drown-item,
    .BetSolrSearchResultPortlet .mobile-header .solr-drill-drown-item-select {
        display: none;
    }
    .BetSolrSearchResultPortlet .mobile-header .solr-drill-drown-param.open .solr-drill-drown-item,
    .BetSolrSearchResultPortlet .mobile-header .solr-drill-drown-param.open .solr-drill-drown-item-select {
        display: block;
        padding: 8px 16px;
        margin:0;
    }
    .BetSolrSearchResultPortlet .mobile-header .selected-drilldrown-params {
        padding: 8px 0;
    }
}

/* Sitemap -----------------------*/

.sitemap.DynamicMenuPortlet {
    margin: 0 16px;
}

.sitemap.DynamicMenuPortlet nav > ul {
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
        -ms-flex-pack:justify;
            justify-content:space-between;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    -webkit-align-items: stretch;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}

.sitemap.DynamicMenuPortlet nav > ul > li > div {
    text-transform:uppercase;
    border-top: 1px solid rgba(123, 122, 154, 0.6);
    border-bottom: 1px solid rgba(123, 122, 154, 0.6);
    padding: 12px 8px 6px;
}

@media (max-width: 980px) {
    .sitemap.DynamicMenuPortlet nav > ul {
        -webkit-flex-flow:wrap;
            -ms-flex-flow:wrap;
                flex-flow:wrap;
    }
}

@media (max-width: 766px) {
    .sitemap.DynamicMenuPortlet {
        width: auto;
        padding: 0px;
        margin: 0 auto;
    }
}

.sitemap.DynamicMenuPortlet ul {
    font-size: 22px;
    list-style-type: none;
    padding-left: 0;
}

.sitemap.DynamicMenuPortlet nav > ul > li {
    margin: 0 8px;
}

@media (max-width:840px) {
    .sitemap.DynamicMenuPortlet nav > ul > li {
        margin: 0 8px 32px 8px;
    }
}

.sitemap.DynamicMenuPortlet ul li {
    margin:8px 0;
    -webkit-box-flex:2;
    -webkit-flex-grow:2;
        -ms-flex-positive:2;
            flex-grow:2;
}


.sitemap.DynamicMenuPortlet ul li ul {
    margin-left: 8px;
    font-size: 18px;
}

.sitemap.DynamicMenuPortlet ul li ul li {
    margin:8px 0;
}

.sitemap.DynamicMenuPortlet ul li ul li ul {
    margin-left: 24px;
    margin-bottom:32px;
    font-size: 16px;
    font-style: normal;
    color:#7b7a9a;
}

.sitemap.DynamicMenuPortlet ul li ul li ul a {
    color:#7b7a9a;
}

.sitemap.DynamicMenuPortlet:nth-child(2) h1 {
    display: none;
}

.sitemap.DynamicMenuPortlet:nth-child(2) h2 {
    font-size: 22px;
    text-transform:uppercase;
    border-top: 1px solid rgba(123, 122, 154, 0.6);
    border-bottom: 1px solid rgba(123, 122, 154, 0.6);
    padding: 12px 8px 6px;
    
}

.sitemap.DynamicMenuPortlet:nth-child(2) ul {
    margin: 0px 8px;
    font-size: 18px;
    display:block;
}

.sitemap.DynamicMenuPortlet:nth-child(2) ul li {
    margin: 0 8px;
}

.sitemap.DynamicMenuPortlet:nth-child(2) nav > ul > li > div {
    display:block;
    text-transform:none;
    font-size: 18px;
    margin: 8px 0;
    padding:0;
    border:0;
}

/* SIDEMENU ------------------*/
.DynamicSubMenuPortlet nav ul {
    list-style:none;
    padding:0;
    margin:0;
    border-top:1px solid rgba(123, 122, 154, 0.6);
}

.DynamicSubMenuPortlet nav ul > li a {
    display:block;
    border-bottom:1px solid rgba(123, 122, 154, 0.6);
    background: #e2e5e8 url(../../../../site-default/modules_site/site/images/kacsacsor.svg) no-repeat 0% 17px;
    display: block;
    padding: 12px 8px 7px 16px;
}

@media all and (max-width: 1010px) {
    .DynamicSubMenuPortlet nav ul > li a {
        background-size: 10px 10px;
        background: #e2e5e8 url(../../../../site-default/modules_site/site/images/kacsacsor.svg) no-repeat 0% 14px;
    }
}

@media all and (max-width: 766px) {
    .DynamicSubMenuPortlet nav ul > li a {
        padding:12px 8px 7px 34px;
        background:#e2e5e8 url(../../../../site-default/modules_site/site/images/kacsacsor.svg) no-repeat 16px 16px;
    }
}

.DynamicSubMenuPortlet nav ul > li > ul {
    margin:0;
    padding-left:16px;
    border-bottom:1px solid rgba(123, 122, 154, 0.6);
    border-top: 0 none;
}

.DynamicSubMenuPortlet nav ul > li:last-child > ul {
    border:0 none;
}

.DynamicSubMenuPortlet nav ul > li > ul > li {
    text-transform: none;
  /*  margin-bottom:8px;*/
}

.DynamicSubMenuPortlet nav ul > li > ul > li a {
    color: #7b7a9a;
    border:0 none;
}

@media all and (max-width: 1010px) {
    .DynamicSubMenuPortlet nav ul {
        font-size:16px;
    }
}

@media all and (max-width: 766px) {
    .DynamicSubMenuPortlet nav ul {
        font-size:18px;
    }
}

.left-side .DynamicBreadcrumbPortlet {
    width:100%;
}

.left-side .DynamicBreadcrumbPortlet ul.breadcrumb {
    margin: 0 16px 16px 0;
}

@media (max-width: 880px) {
    .left-side .DynamicBreadcrumbPortlet ul.breadcrumb {
        margin: 0 0;
    }
}


/* COOKIE_K ------------------------------------*/
#cookie-warn {
	position:fixed;
	bottom:0;
	background:rgba(35, 33, 87, 0.7);
	margin:0 auto 36px;
	width:100%;
	color:#fff;
	text-align:center;
	left:0;
	right:0;
	z-index:10000;
}

	#cookie-warn a {
		color:#cc9933;
		text-decoration:underline;
	}
	
	#cookie-warn a:hover {
		text-decoration:none;
	}

#cookie-warn .inner {
  padding: 24px 16px 8px;
  box-sizing: border-box;
  max-width: 1280px;
  margin: auto;
  text-align: left;
  font-size: 14px;
  position: relative;
  overflow: hidden;
}

#cookie-warn h1 {
	text-transform:uppercase;
	color:#fff;
	font-size:20px;
	font-weight:normal;
	margin-bottom: 8px;
	margin-top:0;
}

#cookie-warn .text {
	width:80%;
	display:inline-block;
	margin-right:20px;
	line-height:24px;
}

#cookie-warn button {
    background-color: #cc9933;
    color: #fff;
    padding: 0 24px;
    font-size: 18px;
    border: 0 none;
    height: 38px;
    text-transform: uppercase;
	position:absolute;
	right: 16px;
	top: 16px;
}

#cookie-warn button:hover {
	background-color:#ba8c5d;
}

@media(max-width:480px) {
    #cookie-warn .text {
        width:100%;
        margin:0 0 16px;
    }
    
    #cookie-warn button {
        position:static;
        float:right;
        
    }
}


span[data-google-map="fancybox"]{
    display: inline-block;
    font-size: 0;
    background-image: url(../../../../site-default/modules_site/site/images/maps-ico.png);
    background-size: 23px 23px;
    background-repeat: no-repeat;
    width: 23px;
    height: 23px;
    line-height: 37px;
    cursor: pointer;
}

.recaptcha > div{
    width:auto!important;
    text-align:center;
}

.hover-wrap.floating-hover {
    width: auto;
    top: auto;
    transform: translateY(-20px);
    position: fixed;
    min-width: 0;
    max-width:250px;
    min-height: 40px;
    line-height: 24px;
    right: auto;
}

@media (max-width:640px) {
    .hover-wrap.floating-hover {
        display:none;
    }
}

.hover-wrap.floating-hover.force-visible {
    transform: translateY(0);
}

.hover-wrap-link {
    text-decoration: underline;
}

[data-field="unregister-btn"] {
    background-color: #cc4533;   
}    

.page-editor-columns{
    display: block;
}

header .DynamicMenuPortlet, .BreadcrumbPortlet{
    margin:0;
}

.select2-container--default .select2-selection--multiple {
    padding: 0;
    height: auto;
    line-height: inherit;
}
.select2-container .select2-search--inline .select2-search__field {
    line-height: normal;
    font-size: 16px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    padding: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 16px;
}

.selectionChoice .selectionChoiceRemove {
    background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 xmlns:xlink=%22http://www.w3.org/1999/xlink%22 version=%221.1%22 id=%22Layer_1%22 x=%220px%22 y=%220px%22 width=%2212px%22 height=%2212px%22 viewBox=%220 0 12 12%22 enable-background=%22new 0 0 12 12%22 xml:space=%22preserve%22%3E %3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 fill=%22%23FFF%22 d=%22M0.923,0L12,11.077L11.077,12L0,0.923L0.923,0z%22/%3E %3Cpath fill-rule=%22evenodd%22 clip-rule=%22evenodd%22 fill=%22%23FFFFFF%22 d=%22M0,11.077L11.077,0L12,0.923L0.923,12L0,11.077z%22/%3E %3C/svg%3E');
    background-position: center;
    background-size: 12px;
}
.fancybox-close-small:focus:after {
   outline:0 none;
}

/* Uj Indexek */

/* MAIN CETOP NTR -------------------*/
.CetopNtrIndexPanelPortlet {}

#cetopNtrIndexPanel-index {
    font-size:48px;
    font-weight:400;
    line-height: 45px;
    overflow:hidden;
}

@media(max-width:766px) {
    #cetopNtrIndexPanel-index {
        line-height:1.3;
    }
}

#cetopNtrIndexPanel-index:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    margin-right: 15px;
    margin-top: -8px;
    vertical-align:middle;
}
    #cetopNtrIndexPanel-index.up:before {
        border-bottom: 12px solid #50924d;
    }

    #cetopNtrIndexPanel-index.down:before {
        border-top: 12px solid #cc4533;
    }

#cetopNtrIndexPanel .graphicon .chart-heading { display: none; }
